import * as React from 'react';
import styled from "styled-components";

interface IPulseDotProps {
    style?: React.CSSProperties,
    color?: string
}

class PulseDot extends React.Component<IPulseDotProps> {
    public render() {
        const defaultColor = this.props.color ?? '#1fa2ff';

        return (
            <Dot style={{...this.props.style}}>
                <circle
                    cx="15"
                    cy="15"
                    r="4"
                    fill={defaultColor}
                />
                <circle
                    cx="15"
                    cy="15"
                    r="8"
                    fill="none"
                    stroke={defaultColor}
                    strokeWidth="2"
                    opacity=".4"
                />
                <circle
                    cx="15"
                    cy="15"
                    r="14"
                    fill="none"
                    stroke={defaultColor}
                    strokeWidth="2"
                    opacity=".1"
                />
            </Dot>
        )
    }
}

export default PulseDot;

const Dot = styled.svg`
  width: 42px;
  height: 42px;
  padding: 6px;
  opacity: 0.5;

  circle {
    opacity: 0;
    animation: fadeInOut 2s infinite;
  }
  
  circle:nth-child(2) {
    animation-delay: 200ms;        
  }
  
  circle:nth-child(3) {
    animation-delay: 400ms;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`