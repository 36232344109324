import * as React from "react";

import { Text } from "rebass";
import { Flex, Box } from "@rebass/grid";
import { navigate } from "gatsby";

import CoverContainer from "../../../../components/containers/CoverContainer";
import CoverContent from "../../../../content/CoverContent";
import ActionButton from "../../../../components/objects/ActionButton";
import styled from "styled-components";
import { ChevronRight } from "@styled-icons/feather";
import { ContentType } from "../../../../components/types/ContentType";

interface IProductCoverProps {
    content: {
        title: string[];
        subtitle: string;
        text: ContentType;
    };
    media: {
        placeholder: string;
    };
    route?: string;
}

/**
 * Implementation of MainCover media container
 * Renders pass-through children components
 */
class ProductCoverImpl extends React.Component<IProductCoverProps> {
    public render() {
        return (
            <CoverContainer
                image={this.props.media?.placeholder ?? ''}
                angle={{
                    polygon: {
                        topLeft: {
                            width: 100,
                            angle: 12,
                        },
                        topRight: {
                            width: 100,
                            angle: 0,
                        },
                        bottomRight: {
                            width: 100,
                            angle: 12,
                        },
                        bottomLeft: {
                            width: 100,
                            angle: 0,
                        },
                    },
                }}
                style={{
                    container: {
                        top: "-10em",
                        marginBottom: "-10em",
                    },
                }}
            >
                <ContentContainer
                    py={["5em", "10em"]}
                    onClick={(e) => {
                        return this.props.route && navigate(this.props.route);
                    }}
                >
                    <CoverContent
                        subtitle={this.props.content?.subtitle ?? ''}
                        title={this.props.content?.title ?? ''}
                        text={
                            <>
                                <Text>{this.props.content?.text}</Text>
                                {this.props.route && (
                                    <StyledButton>
                                        <Text>{"explore"}</Text>
                                        <ChevronRight
                                            width={28}
                                            strokeWidth={2}
                                        />
                                    </StyledButton>
                                )}
                            </>
                        }
                    />
                    <Box height={"3em"}></Box>
                </ContentContainer>
            </CoverContainer>
        );
    }
}

export default ProductCoverImpl;

const StyledButton = styled(ActionButton)`
    padding: 0.5em 1em;
    background: white;
    color: coral;
    font-size: 0.75em;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    letter-spacing: 1em;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    & * {
        margin-right: initial;
    }
`;

const ContentContainer = styled(Flex)`
    transition: ease-in-out 300ms;

    &:hover {
        transform: translate(0, -2.5%);
    }
`;
