import styled from "styled-components";

const ActionButton = styled.button`
  color: white;
  background: rgb(244, 89, 90);
  padding: 1em 2em;
  margin: 1em;
  border-radius: 100px;
  vertical-align: middle;
  transition: ease-in-out 300ms;

  & * {
    display: inline;
    margin-right: 1em;
  }

  &:hover {
    box-shadow: 0 15px 30px rgba(0,0,0,0.15);
    transform: translateY(-4px) scale(1.05);
  }
`

export default ActionButton;