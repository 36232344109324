import * as React from "react";
import { useColorMode } from "theme-ui";
import IThemeProviderProps from "../components/types/IThemeProviderProps";
import themeProps from "../../gatsby-plugin-theme-ui";
import styled from "styled-components";

import { Text, Link } from "rebass";
import { Box } from "@rebass/grid";
import { Calendar } from "@styled-icons/feather";
import ReactMarkdown from "react-markdown";
import ActionButton from "../objects/ActionButton";
import AppRegistry from "../../meta/AppRegistry";
import PulseDot from "../design/PulseDot";

const content = {
    title:
        "Get a complementary **interior design consultation** to change the way you _#LiveLife_ at home.",
    action: "Schedule Sessison",
    footer: AppRegistry.Description.Newsletter.Disclaimer,
};

/**
 * Call to Action for page traffic conversion to scheduled appointments
 * Event Trigger: Scheduler
 */
class CallToAction extends React.Component<IThemeProviderProps, {}> {
    public render() {
        return (
            <Box
                width={3 / 4}
                p={[2, 3]}
                style={{
                    textAlign: "center",
                    margin: "0 auto",
                    color: this.props.fill,
                }}
            >
                <Box p={[1, 2]}>
                    <StyledTitle source={content.title} />
                </Box>
                <Box p={[1, 2]}>
                    <PulseDot
                        style={{
                            marginLeft: "-42px",
                        }}
                    />
                    <Link href={AppRegistry.Contact.Bookings.Min30} target={'_blank'}>
                        <ActionButton>
                            <Calendar width={28} />
                            <Text fontWeight={700}>{content.action}</Text>
                        </ActionButton>
                    </Link>
                </Box>
                <Box p={[1, 2]}>
                    <StyledMarkdown source={content.footer} />
                </Box>
            </Box>
        );
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <CallToAction {...props} fill={fill} />;
};

export default StateWrapper(CallToAction);

const StyledMarkdown = styled(ReactMarkdown)`
    & a {
        color: ${themeProps.colors.grey};
    }
`;

const StyledTitle = styled(StyledMarkdown)`
    font-size: 1.33em;
    font-family: ${themeProps.fonts.serif};
`;